<template>

  <div class="root-container">

    <div :class="homeContainer">
      <div :class="homeContainerLeft">
        <div class="flex-row">
          <div
            class="svg-logo"
            v-html="getRawIcon('RAREIUM_LOGO_LETTER')"
          />
          <h1 class="home-title-text">
            aredex
          </h1>
        </div>
        <div class="home-header-text-container">
          <h2 class="home-header-text">
            Digital tokens physically backed by rare earth metals.
          </h2>
          <div class="home-header-subtext">
            Raredex offers an efficient and convenient means to own high quality industry grade rare earth metals via blockchain tokenization. As we roll out our platform, exciting new tokens will be launched to enable global access to this exlusive market. These tokens are backed by a guaranteed 1 kilogram of high-grade, industry-standard rare earth metals, securely stored in a state-of-the-art, bank-level vault. Each token is accompanied by comprehensive source-of-origin supply chain data, ensuring transparency and accountability. By holding Rareium tokens, you gain direct ownership rights to the underlying rare earth metals, safeguarded by our custodianship and backed by our rigorous security protocols.
          </div>
        </div>
        <div class="home-register-container">
          <div
            @click="openLogin"
            v-if="userInfo.authorized == false"
            class="home-register-button"
          >
            Sign Up.
          </div>
          <div
            @click="gotoTab('exchange')"
            v-if="userInfo.authorized == true"
            class="home-register-button"
          >
            Access Exchange.
          </div>
        </div>
      </div>

      <div
        v-if="homeContainerRight === 'home-container-a-right-desktop'"
        :class="homeContainerRight"
      >
        <div class="diamond-container">
          <Shape />
        </div>
      </div>

    </div>

    <div :class="homeContainer">

      <div :class="homeContainerLeft">

        <div>

          <h2 class="home-header-text">
            Raredex Launch
          </h2>

          <div>
            Pioneer, prospector, explorer, OG. We are so early ! Just as those before us prospected for gold to build the modern world prospectors of today are seeking access to the rare metals to build the future ! mobile phones, batteries, renewable energy, nuclear power, defence, medical, and semiconductors are all reliant on rare earth and technology Metals.
          </div>

          <br />
          <br />

          <div>
            This non-investment pilot coin engages you with our community and will provide access to the rare earth metals ecosystem as we roll out the raredex platform. Participating is a statement of your interest and demonstrating that you feel strongly enough to act on it. By helping us build this platform, we help you access the markets first !
          </div>

        </div>

      </div>

      <div
        v-if="homeContainerRight === 'home-container-a-right-desktop'"
        :class="homeContainerRight"
      >

        <h2 class="home-header-text">
          Community Token
        </h2>
        <div>
          Buy Raredex Token on Solana
        </div>
        <div class="buy-container">
          <div
            class="svg-raydium-logo"
            v-html="getRawIcon('RAYDIUM_ICON')"
          />
          <div>
            (Address Coming Soon)
          </div>
        </div>
        <br />
        <b>The Benefits of Engagement</b>
        <ul>
          <li>
            First Access to Rare Earth Metal Tokens before mass launch.
          </li>
          <li>
            Eligibility for Airdrops of Rare Earth Metals and more.
          </li>
          <li>
            Participate in a community and interact with experts in the space.
          </li>
          <li>
            Have an early seat at a table normally reserved for governments or the well-connected wealthy
          </li>
        </ul>

      </div>

    </div>

    <div :class="homeContainer">
      <div :class="homeContainerLeft">
        <h2 class="home-header-text">
          Tokenomics
        </h2>
          The Raredex token is our community token that will provide the necessary liqudity to enable the platform and long term success. 
        <div class="flex-row">
          <div class="pie-container">
            <PieChart />
          </div>
        </div>
      </div>
      <div :class="homeContainerRight">
        <div class="flex-col chart-key-container">
          <div class="flex-row">
            <div class="metals-slice" />
            <div class="slice-text">
              50% - We strongly believe in the future of rare earth and technology metals, by building a store of tradable liquid metals we will position ourselves in the heard of the ecosystem with the most advanced on off ramp in the world.
            </div>
          </div>
          <div class="flex-row">
            <div class="liquidity-slice" />
            <div class="slice-text">
              30% - Each metal will have it's own trading pair, we will pre-seed these pools with funds on which fees will be earned and sent back to the community through token purchases.
            </div>
          </div>
          <div class="flex-row">
            <div class="operations-slice" />
            <div class="slice-text">
              20% - To build and roll out the platform we will initally utilize 20% of funds to assist in the project, we are committed to spend wisely and ensure the long term success of the project.
            </div>
          </div>
          <div class="flex-row">
            <div class="vc-slice" />
            <div class="slice-text">
              0% - We are building a community movement, we will always strive to keep large investment to a minimum.
            </div>
          </div>
        </div>
      </div>
    </div>

    <h1 class="title-text">
      Rare Earth & Technology Metals
    </h1>

    <div id="metals" :class="homeContainer">

      <D3
        :class="d3GraphContainer"
        :data="chartData"
        :symbol="selectedSymbol"
        :tokens="tokens"
        axisColor="#8eaec0"
        hideXAxis=true
        hideYAxis=true
        hideControls=true
        yTicksIn="5"
        xTicksIn="6"
        strokeWidth="5"
        timeFrame="fiveYear"
        multiGraph=false
        @passback-symbols="updateSymbols"
      />

      <div
        class="home-container-a-box"
      >
        <div class="appreciation-container">

          <div class="five-year-text">

            <h2 class="home-header-text">
              Price Discovery.
            </h2>

            <Transition name="slide-fade" :duration="{ enter: 300, leave: 300 }">

              <div v-if="tokens[selectedSymbol]">
                <span class="five-year-percent-change-text">
                  The chart represents a 
                </span>
                <span class="five-year-percent-change">
                  {{fiveYearChange}}% 
                </span>
                <span class="five-year-percent-change-text">
                  change in {{ tokens[selectedSymbol].name }} ({{ tokens[selectedSymbol].symbol }}) over the past 5 years. Due to the critical supply shortage we are entering price discovery in strategically important rare earth elements. At raredex you can easily purchase individual rare earths or the rare earth index token (REI) which gives you exposure to all rare earths and strategically important technology metals in one easy to buy and hold vehicle. As the Chart shows we are in a bullish consolidation phase, these uncorrelated assets offer tremendous potential both in the short and long term.
                </span>
              </div>

            </Transition>

          </div>

          <Transition name="slide-fade" :duration="{ enter: 300, leave: 300 }">
            <div
              v-if="tokens[selectedSymbol]"
              @click="showMetalModal = true"
              class="home-register-button"
            >
              Graph Another Metal.
            </div>
          </Transition>

        </div>

      </div>

    </div>

    <div class="home-container-a-box">

    </div>

    <Carousel
      id="gallery"
      v-model="currentSlide"
      wrap-around="true"
      items-to-show="1.0"
      autoplay="80000000000000"
      translition="1000"
      pause-autoplay-on-hover="true"
    >
      <Slide
        v-for="(i,j) in Object.entries(tokens).filter(([key, value]) => value.symbol !== 'REI')"
        :key="j"
      >
        <div
          :class="homeContainer"
        >
          <div :class="metalContainer">
            <div class="metal-image">
              <img :src="i[1].image" class="metal-img" >
            </div>
          </div>
          <div class="flex-col metal-text-c">
            <div class="metal-text-container">
              <h1 class="home-header-text">             
                {{i[1].name}} ({{i[1].pts}}.)
              </h1>
              <div class="flex-row-center">
                <div
                  class="symbolicon"
                  v-html="getRawIcon(i[1].icon)"
                />
                <div>
                  <b>{{i[1].symbol}}</b>
                </div>
              </div>
            </div>
            <div class="metal-text">
              {{i[1].text}}
            </div>
          </div>
        </div>
      </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>

    <div>
      <div class="bottom-register-container">
        <div>
          Sign up or login to start your Rare Earth Journey.
        </div>
        <div
          @click="openLogin"
          v-if="userInfo.authorized == false"
          class="home-register-button"
        >
          Register.
        </div>
        <div
          @click="gotoTab('exchange')"
          v-if="userInfo.authorized == true"
          class="home-register-button"
        >
          Access Exchange.
        </div>
      </div>
    </div>

  </div>

  <div
    v-if="serverConfig.view != 'mobile'"
    class="home-news-container-desktop"
  >
    <div
      v-for="(i,j) in news"
      class="home-news-absolute"
    >
      <Transition
        :duration="300"
        name="news-slide-fade"
      >
        <div
          v-if="displayNewsItem == i.id && i.title"
          class="flex-row-center home-news-container-inner"
        >
          <img
            class="home-news-image"
            :src="'../public/images/news_item_' + i.id + '.png'"
          >
          <div :class="newsText">
            <div>
              <b>{{ i.title }}</b>
            </div>
            <div>
              {{ i.text }}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>

  <Transition>
    <CModal
      v-if="showMetalModal"
      color="#5d328d"
      width="small"
      secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
      @close="showMetalModal = false"
    >
      <template #header>
        <div> {{ localize("TEXT_SELECT_METAL") }} </div>
      </template>
      <template #body>
        <div
          v-for="(i,k) in tokens"
          class="home-container-item"
          @click="getChartData(i.symbol)"
        >
          <div class="flex-row-center">
            <div
              class="symbolicon"
              v-html="getRawIcon(i.icon)"
            />
            <div class="home-container-metal-name">
              {{ i.name }}
            </div>
            <div class="home-container-a-symbol">
              {{k}}
            </div>
          </div>
          <div class="flex-row-center-space">
            <div class="home-container-a-price">
              <span>${{ i.price }}</span>
            </div>
            <div
              v-if="i.ytd > 0"
              class="home-container-a-ytd-pos"
            >
              <span>{{ i.ytd }} %</span>
            </div>
            <div
              v-else-if="i.ytd < 0"
              class="home-container-a-ytd-neg"
            >
              <span>{{ i.ytd }} %</span>
            </div>
            <div
              v-else
              class="home-container-a-ytd"
            >
              <span>{{ i.ytd }} %</span>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div />
      </template>
    </CModal>
  </Transition>

</template>

<script>
import {Carousel, Navigation, Pagination, Slide, toRaw} from "vue3-carousel";
import {emit, inject, onMounted, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {translationStore} from "@/stores/translation";
import Shape from "./Shape.vue";
import PieChart from "./PieChart.vue";
import D3 from "./D3.vue";
import Axios from "axios";
import {iconStore} from "@/stores/icon";

export default {
    "name": "Home",
    "components": {
        Carousel,
        Shape,
        PieChart,
        Slide,
        Pagination,
        Navigation,
        D3
    },
    setup (props, {emit}) {

        const translation = translationStore(),
            eBus = inject("eBus"),
            serverConfig = inject("serverConfig"),
            userInfo = inject("userInfo"),
            showMetalModal = ref(false),
            route = useRoute(),
            router = useRouter(),
            d3GraphContainer = ref(),
            selectedSymbol = ref(),
            symbols = ref({}),
            currentSlide = ref(),
            icon = iconStore(),
            currentIndex = ref(0),
            email = ref(""),
            chartData = ref({}),
            fiveYearChange = ref(),
            homeContainer = ref(""),
            homeContainerLeft = ref(""),
            homeContainerRight = ref(""),
            homeHeaderText = ref("home-header-text"),
            homeContentText = ref("home-content-text"),
            homeHeaderTextBlack = ref("home-header-text-black"),
            homeContentTextBlack = ref("home-content-text-black"),
            metalContainer = ref(""),
            updateSymbols = function (e) {

                symbols.value = e;

            },
            openLogin = async function (symbol) {

                    eBus.emit(
                        "app-event",
                        {
                            "action": "showLoginModal",
                            "data": ""
                        }
                    );

            },
            getChartData = async function (symbol) {

                if (tokens.value[selectedSymbol.value] == symbol) {

                    return;

                }

/*
                if (tokens.value[selectedSymbol.value]) {

                    if (tokens.value[selectedSymbol.value].opacity == 1) {

                        tokens.value[selectedSymbol.value].opacity = 0;

                    }

                }
*/
                selectedSymbol.value = 'react';

                let url;

                if (symbol === "REI") {

                    url = `${process.env.VUE_APP_SERVER_URI}/public/rei.json`;

                } else if (symbol === "RDY") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=dysprosiumoxid';

                } else if (symbol === "RPR") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=praseodymoxid';

                } else if (symbol === "RTB") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=terbiumoxid';

                } else if (symbol === "RGA") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=gallium';

                } else if (symbol === "RGE") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=germanium';

                } else if (symbol === "RHF") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=hafnium';

                } else if (symbol === "RIN") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=indium';

                } else if (symbol === "RRE") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=rhenium';

                } else if (symbol === "RND") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=neodymoxid';

                } else {

                    return;

                }

                Axios.get(
                    url,
                    {

                        "params": {

                        }

                    }
                ).
                    then((response) => {

                        showMetalModal.value = false;

                        if (symbol === 'REI') {

                            chartData.value[symbol] = response.data;

                        } else {

                            const result = { usd: {}, eur: {} };
                            Object.keys(response.data).forEach((date) => {

                                result['usd'][date.split('.').reverse().join('-')] = parseFloat(response.data[date].usd);
                                result['eur'][date.split('.').reverse().join('-')] = parseFloat(response.data[date].eur);

                            });

                            chartData.value[symbol] = result;

                        }

                        selectedSymbol.value = symbol;

                        const dates = Object.keys(chartData.value[symbol].usd);

                        if (dates.length === 0) {

                            return null;

                        }

                        const mostRecentDate = dates.reduce((recentDate, currentDate) => {

                            return recentDate > currentDate ? recentDate : currentDate;

                        });

                        fiveYearChange.value = chartData.value[symbol].usd[mostRecentDate].toFixed(0);

                });

            },
            tokens = ref({
                
                "RDY":{
                    icon: "RAREIUM_RGA_ICON",
                    symbol: "RDY",
                    pts: "Dy",
                    color: "#2FB2FF",
                    name: "Dysprosium",
                    text: "Dysprosium is a chemical element with the symbol Dy and atomic number 66. It is a rare earth element with a metallic silver luster. Dysprosium is seldom encountered as a free element in nature and is usually found in minerals such as xenotime, dysprosium-yttrium fluorite, gadolinite and euxenite. 32Dy makes up about 0.06% of the Earth's crust.",
                    image: "/public/images/Dysprosium.png",
                    opacity: 0,
                    price: 578,
                    ytd: -11.48
                },
                "RND":{
                    icon: "RAREIUM_RGA_ICON",
                    symbol: "RND",
                    pts: "Nd",
                    color: "#2FB2FF",
                    name: "Neodymium",
                    text: "Neodymium is a rare earth element that is found in the earth’s crust. Its metallic element number is 60 and its chemical symbol is Nd. It is categorized as a lanthanide. Neodymium has been used since the early 1950s in different products to create magnets with higher magnetic properties than those made with iron or other metals.",
                    image: "/public/images/Neodymium.png",
                    opacity: 0,
                    price: 123,
                    ytd: -41
                },
                "RPR":{
                    icon: "RAREIUM_RGA_ICON",
                    symbol: "RPR",
                    pts: "Pr",
                    color: "#2FB2FF",
                    name: "Praseodymium",
                    text: "Praseodymium is a chemical element belonging to the rare earth group of metals. It has the atomic number 59 and is represented by the symbol Pr on the periodic table. Discovered in 1885 by Carl Auer von Welsbach, praseodymium has a silvery-white color when in its purest form. Strong praseodymium-iron-boron magnets are commonly used in smartphones, wind turbines, and electric motors due to their superior strength and lightweight properties. ",
                    image: "/public/images/Praseodymium.png",
                    opacity: 0,
                    price: 122,
                    ytd: -38
                },
                "RTB":{
                    icon: "RAREIUM_RGA_ICON",
                    symbol: "RTB",
                    pts: "Rb",
                    color: "#2FB2FF",
                    name: "Terbium",
                    text: "Terbium is a chemical element with the symbol Tb and atomic number 65. In metal form, terbium is a silvery-white rare earth metal. In its oxide form, in which it is sold commercially, terbium is a black-brown powder. Terbium does not occur naturally and is considered one of the “rarest” of Rare Earths. Some critical use cases are in LCD screens, fiber optics, alloys and nuclear reactors.",
                    image: "/public/images/Terbium.png",
                    opacity: 0,
                    price: 2230.40,
                    ytd: -44.58
                },
                "RGA":{
                    icon: "RAREIUM_RGA_ICON",
                    symbol: "RGA",
                    pts: "Ga",
                    color: "#2FB2FF",
                    name: "Gallium",
                    text: "Gallium is a chemical element with the symbol Ga and atomic number 31. Gallium is a soft, silvery metal, and Elemental gallium is a liquid at temperatures greater than 29.76C (85.57F) (slightly above room temperature). Solid gallium alloys are used in optics, electronics, and nuclear engineering because of their non-toxicity and resistance to neutron radiation and beta decay.",
                    image: "/public/images/Gallium.png",
                    opacity: 0,
                    price: 755,
                    ytd: 17.95
                },
                "RGE":{
                    icon: "RAREIUM_RGA_ICON",
                    symbol: "RGE",
                    pts: "Ge",
                    color: "#2FB2FF",
                    name: "Germanium",
                    text: "Germanium is a chemical element with the symbol Ge and atomic number 32. It is a grayish-white metalloid with a lustrous appearance and is hard and brittle. A relatively rare element, germanium is a metal that has been used in a variety of applications throughout history. In its purest form, germanium is used as a semiconductor in transistors and other electronic devices. Nowadays, just about every industry uses computers.",
                    image: "/public/images/Germanium.png",
                    opacity: 0,
                    price: 2839.40,
                    ytd: 21.11
                },
                "RHF":{
                    icon: "RAREIUM_RGA_ICON",
                    symbol: "RHF",
                    color: "#2FB2FF",
                    pts: "Hf",
                    name: "Hafnium",
                    text: "Hafnium is a chemical element with the symbol Hf and atomic number 72. Found in zirconium minerals, it’s a shiny, silvery, ductile metal that resists corrosion due to the formation of a tough, impenetrable oxide film on its surface. This strategic metal is used in a variety of industries and applications, including Nuclear Reactors, Aerospace, Chemical applications, Microelectronics and much more",
                    image: "/public/images/Hafnium.png",
                    opacity: 0,
                    price: 5298.90,
                    ytd: 16.20
                },
                "RIN":{
                    icon: "RAREIUM_RGA_ICON",
                    symbol: "RIN",
                    pts: "In",
                    color: "#2FB2FF",
                    name: "Indium",
                    text: "Indium is a rare, malleable, and easily workable metallic element with the atomic number 49 and the symbol In. It has a silvery-lustrous gray appearance and is considered one of the least abundant elements in earth’s crust. Indium is an essential and versatile element with various uses across many industries. Indium has put its unique properties to work in consumer electronics, industrial manufacturing, mining exploration, medical technology, metallurgy, and transportation.",
                    image: "/public/images/Indium.png",
                    opacity: 0,
                    price: 561.60,
                    ytd: 26.97
                },
                "RRE":{
                    icon: "RAREIUM_RGA_ICON",
                    symbol: "RRE",
                    pts: "Re",
                    color: "#2FB2FF",
                    name: "Rhenium",
                    text: "Rhenium is an extremely rare and dense chemical element with atomic number 75, which makes it a part of the transitional metals group. It has a silver-grey appearance and melts at an incredibly high temperature of 3,186 degrees Celsius / 5,767 Fahrenheit. Its unique features have caused rhenium to be used in many different areas, from military and aerospace technology to medical research.",
                    image: "/public/images/Rhenium.png",
                    opacity: 0,
                    price: 1942.20,
                    ytd: 20.89
                },
                "REI":{
                    icon: "RAREIUM_RGA_ICON",
                    symbol: "REI",
                    color: "#2FB2FF",
                    name: "Rare Earth Index",
                    text: "",
                    image: "/public/images/Rhenium.png",
                    opacity: 0,
                    price: 1942.20,
                    ytd: 20.89
                }

            }),
            slideTo = function (v) {

                currentSlide.value = v;

            },
            getIcon = function (i) {

                if (serverConfig.visualMode.value === "dark") {

                    return icon.get(`${i}_DARK`);

                }
                return icon.get(`${i}_LIGHT`);

            },
            getRawIcon = function (i) {

                return icon.get(`${i}`);

            },
            gotoTab = function (t) {

                router.push({"path": `/${t}/`}).catch((err) => {

                    throw err;

                });

            },
            getNews = function () {

                news.splice(0);
                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getNews`,
                    {
                    }
                ).
                    then((response) => {

                        for (const n in response.data) {

                            news.push(response.data[n]);

                        }

                    });

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    homeContainerRight.value = "home-container-a-right-desktop"
                    homeContainerLeft.value = "home-container-a-left-desktop"
                    homeContainer.value = "home-container-desktop"
                    d3GraphContainer.value = "graph-container-desktop"
                    metalContainer.value = "metal-container-desktop"

                } else if (serverConfig.view === "laptop") {

                    homeContainerRight.value = "home-container-a-right-desktop"
                    homeContainerLeft.value = "home-container-a-left-desktop"
                    d3GraphContainer.value = "graph-container-desktop"
                    homeContainer.value = "home-container-desktop"
                    metalContainer.value = "metal-container-desktop"

                } else if (serverConfig.view === "tablet") {

                    homeContainerRight.value = "home-container-a-right-desktop"
                    homeContainerLeft.value = "home-container-a-left-desktop"
                    homeContainer.value = "home-container-desktop"
                    d3GraphContainer.value = "graph-container-desktop"
                    metalContainer.value = "metal-container-desktop"

                } else if (serverConfig.view === "mobile") {

                    homeContainerRight.value = "home-container-a-right-mobile"
                    homeContainerLeft.value = "home-container-a-left-mobile"
                    homeContainer.value = "home-container-mobile"
                    d3GraphContainer.value = "graph-container-mobile"
                    metalContainer.value = "metal-container-mobile"

                } else {

                    homeContainerRight.value = "home-container-a-right-mobile"
                    homeContainerLeft.value = "home-container-a-left-mobile"
                    homeContainer.value = "home-container-mobile"
                    d3GraphContainer.value = "graph-container-mobile"
                    metalContainer.value = "metal-container-mobile"

                }

            };

        onMounted(() => {

            getChartData('REI');
            updateView();

            let run = function() {

              setInterval(() => {

                currentIndex.value++;

                if (currentIndex.value == 7) {

                  currentIndex.value = 0;

                }

              }, 2500);

            }();

            watch(
                () => email.value,

                (first, second) => {

                    console.log(email.value)

                }

            );

            setTimeout(
                () => {

                },
                100
            );

            const runNews = function () {

                setTimeout(
                    () => {

                    },
                    5000
                );

            };
            runNews();

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

        });

        return {"localize": translation.localize,
            openLogin,
            userInfo,
            email,
            currentSlide,
            currentIndex,
            fiveYearChange,
            selectedSymbol,
            chartData,
            d3GraphContainer,
            symbols,
            getIcon,
            getRawIcon,
            gotoTab,
            getChartData,
            showMetalModal,
            metalContainer,
            homeContainer,
            homeContainerLeft,
            homeContainerRight,
            homeHeaderText,
            homeContentText,
            homeHeaderTextBlack,
            homeContentTextBlack,
            tokens,
            serverConfig};

    }
};

</script>
<style scoped>
.separado {
  width: 20px;
  height: 20px;
}
.subtitle {
  max-width: 500px;
  padding: 20px;
}
.carousel-item {
}
.rare-earth-technology {
  font-size: 5em;
  margin: 100px;
  background: -webkit-repeating-linear-gradient(46deg,#2fb2ff,#11245e,#2fb2ff,#11245e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.simple-content-text {
  font-size: 1em;
  font-family: Helvetica,"system-ui";
  font-size: 1em;
  padding: 20px;
  color: #5b702c;
  max-width: 800px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
.normal-content-text {
  font-size: 1em;
  font-family: Helvetica,"system-ui";
  font-size: 1em;
  margin: 20px;
  padding: 20px;
  color: #5b702c;
  border-radius: 20px;
  background: linear-gradient(1deg,#e2e5db63,transparent);
}
.cursive-content-text {
  max-width: 1000px;
  font-size: 4vh;
  margin-left: auto;
  margin-right: auto;
}
.forty-cent {
  width: 40%;
  margin: 10px;
  align-items: center;
  display: flex;
}
.home-header-container {
  position: relative;
}
video.eq-bg-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1.1;
  width: 100%;
}
.home-mission-text-container {
  padding-top: 100px;
}
.home-carousel-container {
  min-height: 820px;
}
.metal-container-desktop {
}
.metal-container-mobile {
  display: flex;
  flex-direction: flex-column-reverse;
  width: 100%;
  height: 100%;
}
.metal-image {
}
img.metal-img {
  width: 100%;
  background-size: contain;
}
.metal-text-c {
  max-width: 500px;
}
.metal-text-container {
  padding: 20px;
  justify-content: center;
  align-items: flex-start;
}
.metal-text {
  font-size: 1.1em;
  padding: 20px;
  display: flex;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
h1.home-content-text {
  display: flex;
  align-items: flex-end;
  color: #4e4e4e;
  margin-top: 25px;
  font-size: 5em;
  margin: 0px;
  padding-top: 30px;
}
.home-content-text {
  display: flex;
  align-items: flex-end;
  color: #4e4e4e;
  margin-top: 25px;
  font-size: 2em;
  font-size: 6vh;
  margin: 0px;
}
h1 .home-header-text {
  color: #4d4d4d;
}
h2 .home-header-text {
  color: #4d4d4d;
}
.title-text {
  justify-content: center;
  display: flex;
  font-weight: normal;
  color: #4d4d4d;
}
.home-title-text {
  margin-left: -20px;
  font-weight: normal;
  color: #4d4d4d;
}
.home-header-subtext {
  font-weight: normal;
  color: #4d4d4d;
  font-size: 1.1em;
}
.home-content-text-mobile {
  color: white;
  font-size: 1.2em;
  font-size: 4vh;
  margin: 0px 50px;
}
h1.home-header-text-mobile {
  margin: 50px;
  color: white;
  font-size: 3em;
  font-size: 10vh;
}
.home-content-text-black {
  color: #5f7237;
  font-size: 2em;
  font-size: 6vh;
  margin: 50px;
}
h1.home-header-text-black {
  margin: 50px;
  color: #000;
  font-size: 5em;
  font-size: 15vh;
}
.home-content-text-mobile-black {
  margin: 30px;
  color: #5f7237;
  font-size: 1.2em;
  font-size: 4vh;
}
h1.home-header-text-mobile-black {
  color: #000; 
  font-size: 3em;
  font-size: 10vh;
}
.home-platform-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
img.large-platform-img {
  margin: 10px;
  width: 90%;
}
img.platform-img {
  margin: 10px;
  width: 100%;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.home-curve-container {
}
svg.home-mission-curve {
  position: absolute;
  background: transparent;
  left: 0;
  bottom: 0;
}
svg.home-curve {
  z-index: -2;
  position: absolute;
  background: radial-gradient(ellipse at 50% 70%,#7d5cff,rgba(255,92,244,.47000000000000003));
  left: 0;
}
.raredex-logo {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  bottom: 10%;
}
.raredex-logo-img {
  width: 30%;
  max-width: 500px;
  min-width: 300px;
  margin-bottom: 50px;
}
.colorstroke {
  width: 100%;
  height: 100%;
  max-height: 100px;
  max-width: 800px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
.governance-backdrop {
  background-size: auto;
  background-position: center;
  margin-left: 10px;
  margin-right: 10px;
}
.subtitle {
  max-width: 500px;
  padding: 20px;
  font-size: 3em;
  font-size: 1.0em;
  line-height: 30px;
  text-align: left;
}
a.contactus {
  color: #322e6a;
  text-decoration: underline;
  cursor: pointer;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.good-link {
  color: #3783bb;
}
.home-roadmap-container {
  font-size: 1.1em;
  width: 90%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}
.home-roadmap-box-left-a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-bottom: 10px solid #ced4c2;
  border-right: 10px solid #ced4c2;
  background-color: #f8faff;
}
.home-roadmap-box-right-a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  background-color: #f8faff;
}
.home-roadmap-box-left-b {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  background-color: #f8faff;
}
.home-roadmap-box-right-b {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-left: 10px solid #ced4c2;
  border-bottom: 10px solid #ced4c2;
  background-color: #f8faff;
}
.founders-container {
  max-width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.compdeep-logo {
  cursor: pointer;
  width: 100%;
  height: 100px;
  background-image: url('../assets/compdeep-banner-plain.png');
  margin: 20px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: contain;
  max-width: 300px;
}
.home-footer {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.voxels-container {
  position: relative;
  padding: 0;
  margin-top: 100px;
  width: 100%;
  display: grid;
  place-content: center;
  grid-template-columns: repeat(33,3%);
}
.voxels {
  background: #53b2fe;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  scale: 0;
  opacity: 0;
  animation: box 5s linear infinite;
}
@keyframes box {
  0%, 50%, 90% { scale: 1; opacity: 1 }
  25%, 75%, 100% { scale: 0; opacity: 0 }
}
.root-container {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}
.home-container-desktop {
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
}
.home-container-mobile {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px;
  max-width: 1600px;
}
.home-container-a-left-mobile {
  width: 100%;
}
.home-container-a-right-mobile {
  width: 100%;
}
.home-container-a-left-desktop {
  width: 50%;
}
.home-container-a-right-desktop {
  width: 50%;
  margin-left: 100px;
}
.home-container-a-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 10px;
  padding: 10px;
  min-width: 300px;
  -webkit-flex: 1;
  -ms-flex: 1;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.symbolicon {
  display: flex;
  width: 36px;
  height: 36px;
}
.home-container-a-price {
  background-color: none;
  text-align: left;
  -webkit-flex: unset;
  -ms-flex: unset;
  flex: unset;
}
.home-container-metal-name {
  color: #505050;
  font-size: 0.8em;
}
.home-container-a-symbol {
  color: #505050;
  font-weight: bold;
  font-size: 0.6em;
  margin-left: 5px;
  margin-right: 5px;
}
.home-container-a-price {
  color: #505050;
  font-size: 0.8em;
  font-weigth: bold;
}
.home-container-a-ytd {
  font-size: 0.8em;
  color: grey; 
  font-weight: bold;
}
.home-container-a-ytd-neg {
  font-size: 0.8em;
  color: #ff7cf5;
  font-weight: bold;
}
.home-container-a-ytd-pos {
  font-size: 0.8em;
  color: #46b6ff;
  font-weight: bold;
}
.home-container-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: 70vh;
  margin: 5px;
  cursor: pointer;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-row-center-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}
.voxels-text-container {
  position: absolute;
  color: #232b3f;
  font-weight: 700;
  width: 100%;
  text-align: center;
  top: 150px;
}
.buy-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.svg-raydium-logo {
  width: 42px;
  height: 42px;
  display: flex;
}
.svg-logo {
  display: flex;
}
.home-header-text-container {
}
.home-register-container {
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
}
.bottom-register-container {
  display: flex;
  align-items: center; 
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.home-register-button {
  display: flex;
  margin: 10px;
  width: fit-content;
  align-items: center;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  background: linear-gradient(338deg,#ff7cf5,#46b6ff);
}
.home-main-asset {
  min-width: 300px;
  width: 50%;
  height: 400px;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/FrameToken.png");
}
.graph-container-desktop {
  height: 50vh;
  width: 50%;
  margin: 10px;
  padding: 10px;
}
.graph-container-mobile {
  height: 80vh;
  width: 100%;
  margin-left: -40px;
}
.pie-container {
  margin-top: -100px;
  height: 500px;
  min-width: 300px;
  width: 50%;
}
.diamond-container {
  height: 500px;
  min-width: 300px;
  width: 50%;
}
.five-year-text {
  min-height: 300px;
  margin: 10px;
}
.five-year-percent-change {
  font-weight: bold;
  color: rgb(47, 178, 255);
}
.five-year-percent-change-text {
  color: #061d2b;
}
.appreciation-container {
  max-width: 500px;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.slide-fade-enter-active {
    transition: all 1.5s ease-out;
}

.slide-fade-leave-active {
    transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
.voxels-text {
  font-size: 9em;
  background: -webkit-repeating-linear-gradient(46deg,#2fb2ff,#11245e,#2fb2ff,#11245e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.operations-slice {
  margin: 10px;
  padding: 1px;
  height: 32px;
  width: 32px;
  background-color: #7612b3;
}
.metals-slice {
  margin: 10px;
  padding: 1px;
  height: 32px;
  width: 32px;
  background-color: #40a5e7;
}
.vc-slice {
  margin: 10px;
  padding: 1px;
  height: 32px;
  width: 32px;
  background-color: #ea72e0;
}
.liquidity-slice {
  margin: 10px;
  padding: 1px;
  height: 32px;
  width: 32px;
  background-color: #e1bb0d;
}
.slice-text {
  margin: 10px;
  max-width: 500px;
}
.chart-key-container {
  margin-top: 70px;
}
/*
@keyframes rotateDiamond {
    from {
        transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
    }
    to {
        transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
    }
}
*/
</style>

<style lang="scss" scoped>

  @for $i from 1 through 1000 {
    .voxels {
      &:nth-child(#{$i}) {
        animation-delay: calc(
          -10s * #{$i} * sin(#{$i})
        );
      }
    }
  }

  @keyframes m {
    0%, 50%, 90% { scale: 1; opacity: 1 }
    25%, 75%, 100% { scale: 0; opacity: 0 }
  }
</style>
