<template>
  <div
    class="modal-dialog"
    v-if="tokens"
  >
    <LocalizedContent ref="lc" />
    <div class="on-off-chain-container">
      <CSwitch
        class="on-off-chain-switch"
        :title="onOffChain"
        :checked="isOnChain"
        @inputValue="setOnOffChain($event)"
      />
    </div>
    <div class="flex-col">
      <div class="super-line">
        <div class="super-text">
          <b> {{ localize("TEXT_SELL") }} </b>
        </div>
        <div
          v-if="isFiat(sellToken)"
          class="super-text"
        >
          {{ localize("TEXT_BALANCE") }} : {{ (tokens[sellToken].balance/100).toFixed(2) }}.
        </div>
        <div
          v-if="isMetal(sellToken)"
          class="super-text"
        >
          {{ localize("TEXT_BALANCE") }} : {{ (tokens[sellToken].balance/1000).toFixed(3) }} kg.
        </div>
      </div>
      <div class="pay-out">
        <div
          v-if="tokens[sellToken]"
          class="token-dropdown"
          @click="openTokensModal('sell')"
        >
          <div class="swap-token-container margin-sell">
            <div v-if="isUrl(tokens[sellToken].icon) === true">
              <img
                class="token-img"
                :src="tokens[sellToken].icon"
              >
            </div>
            <div v-else>
              <div
                class="token-icon"
                v-html="getRawIcon(tokens[sellToken].icon)"
              />
            </div>
            <div class="symbol-text">
              {{ tokens[sellToken].symbol }}
            </div>
            <div
              class="token-icon"
              v-html="getRawIcon('EXPAND_ARROW_DOWN_LIGHT')"
            />
          </div>
        </div>
        <div class="pay-amount">
          <div>
<!--
              @keydown="formatSellAmount()"
-->
            <input
              placeholder="0.00"
              @input="event => sellAmount = event.target.value"
              type="number"
              step="0.1"
              class="pay-amount-input" />
          </div>
          <div v-if="isFiat(sellToken)" class="pay-in-amount-subscript">
            ${{ sellAmount * (tokens[sellToken].price/100).toFixed(2) }}
          </div>
          <div v-if="isMetal(sellToken)" class="pay-in-amount-subscript">
            ${{ sellAmount * (tokens[sellToken].price/100).toFixed(2) }}
          </div>
        </div>
      </div>
      <div
        @click="reverseSwap"
        class="reverse-container"
      >
        <div class="reverse-swap-icon">
          <div class="rsi" v-html="getRawIcon('SWAP')" />
        </div>
      </div>
      <div class="super-line">
        <div class="super-text">
          <b> {{ localize("TEXT_RECEIVE") }} </b>
        </div>
        <div
          v-if="isFiat(buyToken)"
          class="super-text">
          {{ localize("TEXT_BALANCE") }} : {{ (tokens[buyToken].balance/100).toFixed(2) }}
        </div>
        <div
          v-if="isMetal(buyToken)"
          class="super-text">
          {{ localize("TEXT_BALANCE") }} : {{ (tokens[buyToken].balance/1000).toFixed(3) }} kg.
        </div>
      </div>
      <div class="pay-in-outer">
        <div class="pay-in-inner">
          <div
            v-if="tokens[buyToken]"
            class="token-dropdown"
            @click="openTokensModal('buy')"
          >
            <div class="swap-token-container margin-buy">
              <div v-if="isUrl(tokens[buyToken].icon) === true">
                <img
                  class="token-img"
                  :src="tokens[buyToken].icon"
                >
              </div>
              <div v-else>
                <div
                  class="token-icon"
                  v-html="getRawIcon(tokens[buyToken].icon)"
                />
              </div>
              <div class="symbol-text">
                {{ tokens[buyToken].symbol }}
              </div>
              <div
                class="token-icon"
                v-html="getRawIcon('EXPAND_ARROW_DOWN_LIGHT')"
              />
            </div>
          </div>
          <div
            class="pay-amount"
            v-if="tokens[buyToken]"
          >
            <div v-if="buyToken == 'USD' || sellToken == 'EUR'" class="pay-amount-input" >
              {{ (receiveAmount / 100).toFixed(2) }}
            </div>
            <div v-else-if="receiveAmount" class="pay-amount-input" >
              {{ parseFloat(receiveAmount / 1000).toFixed(3) }}
            </div>
            <div v-if="buyToken == 'USD' || sellToken == 'EUR'" class="pay-out-amount-subscript">
              ${{ (receiveAmount * tokens[buyToken].price/100).toFixed(2) }}
            </div>
            <div v-else-if="receiveAmount" class="pay-out-amount-subscript">
              ${{ (receiveAmount * tokens[buyToken].price/100000).toFixed(2) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="flex-row">
      <div>
      </div>
    </div>
    <div
      class="swap-text-box"
      @click=placeOrder
    >
      <span><span class="swap-text">Swap</span></span>
    </div>
    <div>
      <span
        id="message"
        class="success"
      >
        {{ message }}
      </span>
    </div>

    <Transform>
      <CModal
        v-if="showTokensModal"
        width="small"
        color="#efefef"
        backgroundColor="#333"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showTokensModal = false"
      >
        <template #header>
          <div> {{ localize("TEXT_CHOOSE_TOKEN") }} </div>
        </template>
        <template #body>
          <div
            v-if="isOnChain == false"
            class="token-modal-container">
            <div
              v-for="(t,i) in tokens"
              :key="i"
              @click="changeToken(t.symbol)"
            >
              <div class="swap-token-container margin-sell">
                <div class="flex-row">
                  <div v-if="isUrl(t.icon) === true">
                    <img
                      class="token-img"
                      :src="t.icon"
                    >
                  </div>
                  <div v-else>
                    <div
                      class="token-icon"
                      v-html="getRawIcon(t.icon)"
                    />
                  </div>
                  <div class="flex-col">
                    <div class="symbol-text">
                      {{ t.symbol }}
                    </div>
                    <div class="subscript">
                      {{ t.name }}
                    </div>
                  </div>
                </div>
                <div class="token-pricing">
                  <div class="flex-col">
                    <div class="balance-text">
                      {{ (t.balance/1000).toFixed(3) }} kg.
                    </div>
                    <div class="subscript">
                      ${{ (t.balance/1000 * t.price/100).toFixed(2) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="isOnChain == true"
            class="token-modal-container"
          >
            <div
              v-for="(t,i) in tokens"
              :key="i"
              @click="changeToken(t.symbol)"
            >
              <div
                v-if="t.address"
                class="swap-token-container margin-sell"
              >
                <div class="flex-row">
                  <div v-if="isUrl(t.icon) === true">
                    <img
                      class="token-img"
                      :src="t.icon"
                    >
                  </div>
                  <div v-else>
                    <div
                      class="token-icon"
                      v-html="getRawIcon(t.icon)"
                    />
                  </div>
                  <div class="flex-col">
                    <div class="symbol-text">
                      {{ t.symbol }}
                    </div>
                    <div class="subscript">
                      {{ t.name }}
                    </div>
                  </div>
                </div>
                <div class="token-pricing">
                  <div class="flex-col">
                    <div class="balance-text">
                      {{ t.balance }}
                    </div>
                    <div class="subscript">
                      ${{ (t.balance/1000 * t.price/100).toFixed(2) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transform>

    <Transform>
      <CModal
        v-if="showSuccessModal"
        width="small"
        color="#efefef"
        backgroundColor="#333"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showSuccessModal = false"
      >
        <template #header>
          <div> {{ localize("TEXT_SUCCESS") }} </div>
        </template>
        <template #body>
          <div>
            <div
              class="success"
              v-html="getRawIcon('CIRCLE_CHECK')"
            />
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transform>
  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {emit, inject, onMounted, ref, toRef, toRefs, watch} from "vue";
import {translationStore} from "@/stores/translation";
import Axios from "axios";

export default {
    "name": "Swap",
    "components": {
    },
    "props": {
        "swapToken": {
            type: String,
            default: ""
        },
        "swapTokens": {
            type: Array,
            default: []
        },
        "swapAmount":{
            type: Number,
            default: 0
        }
    },
    "emits": [
        "hide-modal-event",
        "get-orders"
    ],
    setup (props, {emit}) {

        const web3 = inject("web3"),
            serverConfig = toRefs(inject("serverConfig")),
            icon = iconStore(),
            translation = translationStore(),
            showTokensModal = ref(false),
            showSuccessModal = ref(false),
            buyOrSell = ref(),
            buyToken = ref(),
            sellToken = ref(),
            token = toRef(props, 'swapToken'),
            tokens = toRef(props, 'swapTokens'),
            sellAmount = toRef(props, 'swapAmount'),
            currency = ref('USD'),
            receiveAmount = ref(),
            swapFee = ref(),
            message = ref(),
            isOnChain = ref(false),
            onOffChain = ref('Off Chain'),
            isFiat = function (s) {

                return ["USD", "EUR"].includes(s);

            },
            isMetal = function (s) {

                return ["RDY", "RND", "RPR", "RTB", "RGA", "RGE", "RHF", "RIN", "RRE"].includes(s);

            },
            formatSellAmount = function () {

                let v = sellAmount.value;

                if (!v) {

                    v = '0';

                }

                v = v.replace(/^0+/, '');

                if (sellToken.value === "USD" || sellToken.value === "EUR") {

                    if (!v) {

                        return 0.00;

                    }

                    v = v.replace('.','');

                    if (v.length == 1) {

                        return '0.0' + v;

                    } else if (v.length == 2) {

                        return '0.' + v;

                    } else {

                        console.log(v)
                        return v.substring(0,v.length-2) + "." + v.substring(v.length-2);
        
                    }

                }

                return v;

            },
            placeOrder = function (v) {

                var amount;

                if (sellToken.value == "USD" || sellToken.value == "EUR") {

                    amount = sellAmount.value * 100;

                } else {

                    amount = sellAmount.value;

                }

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}placeOrder`,
                    {
                        "params": {

                            sellToken: sellToken.value,
                            buyToken: buyToken.value,
                            sellAmount: amount,
                            buyPrice: tokens.value[buyToken.value].price,
                            orderType: 'market',
                            currency: currency.value

                        }
                    }
                ).
                    then((response) => {

                        if (response.status === 200 && response.data) {

                            emitHideModal();
                            emit(
                                "get-orders",
                                false
                            );
                            showSuccessModal.value = true;
                            setTimeout(
                                () => {

                                    showSuccessModal.value = false;

                                },
                                3000
                            );

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getSwap = function() {

                var amount;

                // process fiat in int
                if (sellToken.value == "USD" || sellToken.value == "EUR") {

                    amount = sellAmount.value * 100;

                } else {

                    amount = sellAmount.value;

                }

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getSwap`,
                    {
                        "params": {

                            sellToken: sellToken.value,
                            buyToken: buyToken.value,
                            amount,
                            currency: currency.value

                        }
                    }
                ).
                    then((response) => {

                        if (response.status === 200 && response.data) {

                            receiveAmount.value = response.data.swap_price;
                            swapFee.value = response.data.swap_fee;

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            reverseSwap = function () {

                let s = sellToken.value;
                sellToken.value = buyToken.value;
                buyToken.value = s;

            },
            openTokensModal = function(bos) {

                buyOrSell.value = bos;
                showTokensModal.value = true

            },
            changeToken = function (t) {

                if (buyOrSell.value === 'sell') {

                    sellToken.value = t;

                }
                
                if (buyOrSell.value === 'buy') {

                    buyToken.value = t;

                }

                showTokensModal.value = false;

            },
            setOnOffChain = function (e) {

                isOnChain.value = e;

                if (e === false) {

                    onOffChain.value = "Off Chain";

                } else if (e === true) {

                    onOffChain.value = "On Chain";

                }

            },
            emitHideModal = () => {

                emit(
                    "hide-modal-event",
                    false
                );

            },
            getAllTokens = function () {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getAllTokens`,
                    {
                        "params": {
                        }
                    }
                ).
                    then((response) => {

                        if (response.status === 200 && response.data) {


                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getMyTokens = function () {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getMyTokens`,
                    {
                        "params": {
                        }
                    }
                ).
                    then((response) => {

                        loading.value = false;

                        if (response.status === 200 && response.data) {


                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            swap = function () {

                if (loginClass.value == "send-inactive") {

                    validatePassword();
                    return;

                }
                Axios.post(
                    `${process.env.VUE_APP_SERVER_URI}login`,
                    {
                        "email": email.value,
                        "password": password.value
                    }
                ).
                    then((response) => {

                        if (response.data) {

                            if (response.data.authorized == true) {

                                document.getElementById("message").className = "success";
                                setTimeout(
                                    () => {

                                        close();

                                    },
                                    500
                                );

                            } else {

                                document.getElementById("message").className = "error";
                                message.value = translation.localize("LOGIN_FAILED");

                            }

                        }

                    }).
                    catch((error) => {

                        console.log(error);

                    });

            },
            isUrl = function (i) {

                return i.includes("://");

            },
            getRawIcon = function (i) {

                return icon.get(`${i}`);

            },
            close = function () {

                emitHideModal();

            };
        onMounted(() => {

            watch(
                token.value,
                (n,o) => {

                    // TODO : we should work out which is buy and sell
                    buyToken.value = token.value;
                    sellToken.value = 'USD';

                }, {immediate: true}

            );

            watch(
                sellToken,
                () => {

                    getSwap();

                }
            );

            watch(
                buyToken,
                () => {

                    getSwap();

                }
            );

            watch(
                sellAmount,
                () => {

                    getSwap();

                }
            );

        });
        return {"localize": translation.localize,
            serverConfig,
            getSwap,
            reverseSwap,
            buyOrSell,
            changeToken,
            openTokensModal,
            showTokensModal,
            showSuccessModal,
            isOnChain,
            onOffChain,
            setOnOffChain,
            isFiat,
            isMetal,
            buyToken,
            sellToken,
            swapFee,
            receiveAmount,
            sellAmount,
            placeOrder,
            formatSellAmount,
            currency,
            isUrl,
            getRawIcon,
            token,
            tokens,
            web3,
            icon,
            close,
            swap,
            emitHideModal};

    }
};

</script>
<style scoped>
input .sellAmount {
  style: none;
}
.super-line {
  display: flex;
  justify-content: space-between;
  margin: 5px 10px;
}
.super-text {
  font-size: 0.8em;
  color: #efefef;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.title-mobile {
  margin-left: 0px;
}
.title-desktop {
  margin-left: 10px;
}
.subscript {
  color: #777;
  font-size: 0.7em;
  font-weight: bold;
  text-align: right;
}
.content-container {
  margin-top: 150px;
}
.modal-dialog {
  pointer-events: all;
}
.send-inactive {
  float: left;
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 5px;
  border-color: #ff7dcb;
  color: #ff7dcb;
  font-size: 0.8em;
  font-weight: bold;
}
.send-active {
  float: left;
  cursor: pointer;
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 5px;
  border-color: #e10087;
  background-color: #e10087;
  font-weight: bold;
  color: #fff;
  font-size: 0.8em;
}
.pay-in-outer {
  display: flex;
  justify-content: space-between;
  height: 80px;
  background: -webkit-repeating-linear-gradient(46deg,#46b6ff,#ff7cf5);
  border-radius: 15px;
}
.pay-in-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(45deg, #210322, #031529);
  margin: 5px;
  border-radius: 12px;
}
.pay-out {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  background: linear-gradient(45deg, #6a6a6a, #4a4a4a);
  border-radius: 15px;
}
.pay-amount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}
.pay-in-amount-subscript {
  color: #999;
  font-size: 0.75em;
  font-weight: bold;
  text-align: right;
  margin-right: 39px;
}
.pay-out-amount-subscript {
  color: #999;
  font-size: 0.75em;
  font-weight: bold;
  text-align: right;
  margin-right: 35px;
}
.pay-amount-input {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  background: inherit;
  font-size: 1.44em;
  color: white;
  max-width: 240px;
  height: 30px;
  text-align: right;
  margin: 0px 20px;
  color: #efefef;
}
.reverse-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #555;
  margin-bottom: 35px;
  position: relative;
}
.reverse-swap-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 20px;
  width: 32px;
  height: 32px;
  padding: 2px;
  color: #777;
  border: 1px solid #555;
  cursor: pointer;
  background-color: #333;
  transition: all 300ms ease-in-out;
}
.reverse-swap-icon:hover {
  background: radial-gradient(#4e2e4c, #393939);
  color: rgb(255, 124, 245);
}
.error {
  float: left;
  margin: 15px;
  color: grey;
}
.success {
  float: left;
  margin: 15px;
  color: #e10087;
}
.swap-text-box {
  padding: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 300ms;
  border: 2px solid transparent;
  background: #555;
}
.swap-text-box:hover {
  border: 2px solid #46b6ff;
  cursor: pointer;
}
.swap-text {
  width: 100px;
  font-size: 1.2em;
  font-weight: bold;
  background: -webkit-repeating-linear-gradient(46deg,#46b6ff,#ff7cf5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#message {
  font-size: 0.8em;
  margin: 15px;
  transition: all 500ms ease-in-out;
}
.on-off-chain-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.on-off-chain-switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.token-dropdown {
  color: #fff;
}
.symbol-text {
  color: #efefef;
  font-size: 0.85em;
  font-weight: bold;
}
.balance-text {
  color: #efefef;
  font-size: 0.85em;
  font-weight: bold;
  text-align: right;
}
.token-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.margin-sell {
  margin: 10px;
  padding: 9px 12px;
}
.margin-buy {
  margin: 10px;
  padding: 10px;
}
.swap-token-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #444;
  border: 1px solid #444;
  border-radius: 9px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.swap-token-container:hover {
  border: 1px solid #46b6ff;
  box-shadow: inset 0px 0px 10px 1px #46b6ff, 0px 0px 5px 1px #46b6ff;
}
.rsi {
  padding-top: 5px;
}
textarea:focus, input:focus{
    outline: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.circ{
    opacity: 0;
    stroke-dasharray: 130;
    stroke-dashoffset: 130;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}
.tick{
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    -webkit-transition: stroke-dashoffset 1s 0.5s ease-out;
    -moz-transition: stroke-dashoffset 1s 0.5s ease-out;
    -ms-transition: stroke-dashoffset 1s 0.5s ease-out;
    -o-transition: stroke-dashoffset 1s 0.5s ease-out;
    transition: stroke-dashoffset 1s 0.5s ease-out;
}
.success + svg .path{
    opacity: 1;
    stroke-dashoffset: 0;
}
</style>
